import { useMutation } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { LOGIN } from './queries';
import { login, loginVariables } from './__generated__/login';
import Logo from '../../assets/home-512.png';
import { fromEntries } from '../../utils';
import { getFormData } from '../RealEstates';

interface FormData {
  emailAddress: string;
  password: string;
}

const Login = ({}: {}) => {
  const [error, setError] = useState(false);
  const [loginFn] = useMutation<login, loginVariables>(LOGIN);
  const history = useHistory();
  const login = (form: FormData) => {
    setError(false);
    loginFn({ variables: form })
      .then((data) => {
        if (data.data?.login) localStorage.setItem('loginToken', data.data?.login);
        history.push('/vastgoed');
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '800px',
        height: '100%',
        width: '100%',
      }}>
      <Form.Row style={{ alignItems: 'center' }}>
        <img src={Logo} style={{ width: '120px', height: '120px' }} />
        <div style={{ marginLeft: '20px', fontSize: '50px', fontWeight: 100 }}>Immo History</div>
      </Form.Row>
      <Form
        style={{
          marginBottom: '20px',
          width: '100%',
          border: '1px solid #cccccc',
          borderRadius: '4px',
          padding: '16px',
        }}
        onSubmit={(e: any) => {
          e.preventDefault();
          const formData = getFormData(e.target.elements) as unknown as FormData;
          login(formData);
        }}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>E-mailadres</Form.Label>
            <Form.Control type="email" placeholder="E-mailadres" name="emailAddress" />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Wachtwoord</Form.Label>
            <Form.Control type="password" placeholder="Wachtwoord" name="password" />
          </Form.Group>
        </Form.Row>
        <Form.Row style={{ alignItems: 'center' }}>
          <Button variant="primary" type="submit">
            Inloggen
          </Button>
          {error && <div style={{ color: 'red', marginLeft: '16px' }}>Fout e-mailadres en/of wachtwoord</div>}
        </Form.Row>
      </Form>
    </div>
  );
};

export default Login;
