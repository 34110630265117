import React, { Fragment, useEffect, useState } from 'react';
import { useQuery, gql, FetchMoreOptions } from '@apollo/client';
import { Carousel } from 'react-responsive-carousel';
import {
  realEstates,
  realEstatesVariables,
  realEstates_realEstates,
  realEstates_realEstates_data,
} from './__generated__/realEstates';
import moment from 'moment';
import { Button, Col, Form, Spinner } from 'react-bootstrap';
import { REAL_ESTATES } from './queries';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import Logo from '../../assets/home-512.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const realEstateSubTypeMap: { [key: string]: string } = {
  APARTMENT: 'Appartement',
  PENTHOUSE: 'Penthouse',
  SERVICE_FLAT: 'Service flat',
  LOFT: 'Loft',
  DUPLEX: 'Duplex',
  TRIPLEX: 'Triplex',
  FLAT_STUDIO: 'Studio',
  APARTMENT_BLOCK: 'Appartementsblok',
  GROUND_FLOOR: 'Gelijkvloers',
  HOUSE: 'Huis',
  CHALET: 'Chalet',
  VILLA: 'Villa',
  MANSION: 'Mansion',
  MIXED_USE_BUILDING: 'Gemengd gebruik',
  CASTLE: 'Kasteel',
  BUNGALOW: 'Bungalow',
  COUNTRY_COTTAGE: 'Landelijk',
  EXCEPTIONAL_PROPERTY: 'Uitzonderlijke eigendom',
  TOWN_HOUSE: 'Stadswoning',
  OTHER_PROPERTY: 'Andere eigendom',
  BUSINESS: 'Zakelijk',
  MIXED_USE_BUILDING_COMMERCIAL: 'Gemengd gebruik',
  HOTEL_RESTAURANT_CAFE: 'Hotel - Restaurant - Cafe',
  COMMERCIAL_PREMISES: 'Commercieël vastgoed',
  LAND: 'Grond',
  BUILDING_LAND: 'Brouwgrond',
  FOREST: 'Bos',
  AGRICULTURAL_AREA: 'Landbouwgrond',
  UNBUILDABLE_LAND: 'Geen brouwgrond',
  INDUSTRIAL_PREMISES: 'Industrieël vastgoed',
  WAREHOUSE: 'Warenhuis',
  MIXED_USE_BUILDING_INDUSTRIES: 'Gemengd gebruik',
};

const realEstateTypeMap: { [key: string]: string } = {
  APARTMENT_GROUP: 'Groep appartementen',
  APARTMENT: 'Appartement',
  HOUSE: 'Huis',
  LAND: 'Bouwgrond',
  INDUSTRY: 'Industrie',
  COMMERCIAL: 'Commercieël',
  HOUSE_GROUP: 'Group huizen',
};

const RealEstateCarousel = ({
  realEstate,
  setIsFullScreen,
  isFullScreen,
}: {
  realEstate: realEstates_realEstates_data;
  setIsFullScreen: (data: realEstates_realEstates_data | null) => void;
  isFullScreen: boolean;
}) => {
  const closeFullScreenListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsFullScreen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeFullScreenListener, false);
    return () =>
      document.removeEventListener('keydown', closeFullScreenListener, false);
  }, []);

  if (isFullScreen) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10,
          backgroundColor: 'black',
        }}>
        <div
          style={{
            position: 'absolute',
            top: '50px',
            right: '100px',
            color: 'white',
            fontSize: '30px',
            cursor: 'pointer',
            zIndex: 1,
          }}
          onClick={() => {
            setIsFullScreen(null);
          }}>
          Sluiten
        </div>
        <div
          style={{
            maxWidth: '1200px',
            maxHeight: '1200px',
            width: '80%',
            height: '80%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Carousel infiniteLoop showThumbs={true} useKeyboardArrows autoFocus>
            {realEstate.media?.pictures?.map((picture, index) => (
              <div
                key={`Picture${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => setIsFullScreen && setIsFullScreen(realEstate)}>
                <img
                  src={picture?.largeUrl || ''}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                    maxWidth: '1200px',
                    maxHeight: '1000px',
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }

  return (
    <Carousel infiniteLoop showThumbs={true} useKeyboardArrows>
      {realEstate.media?.pictures?.map((picture, index) => (
        <div
          key={`Picture${index}`}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setIsFullScreen && setIsFullScreen(realEstate)}>
          <img
            src={picture?.largeUrl || ''}
            style={{
              objectFit: 'contain',
              maxWidth: '600px',
              maxHeight: '600px',
            }}
          />
        </div>
      ))}
    </Carousel>
  );
};

const RealEstate = ({
  realEstate,
  setIsFullScreen,
}: {
  realEstate: realEstates_realEstates_data;
  setIsFullScreen: (data: realEstates_realEstates_data | null) => void;
}) => {
  return (
    <div style={{ display: 'flex', height: '800px' }}>
      <div style={{ minWidth: '200px', flex: 1, height: '800px' }}>
        <RealEstateCarousel
          realEstate={realEstate}
          setIsFullScreen={setIsFullScreen}
          isFullScreen={false}
        />
      </div>
      <div style={{ padding: '0px 10px', flex: 2 }}>
        <div>
          <span style={{ fontWeight: 'bold' }}>Datum: </span>
          {(realEstate.publication?.creationDate &&
            moment(realEstate.publication?.creationDate).format(
              'DD/MM/YYYY'
            )) ||
            '-'}
        </div>
        <div style={{ width: '100%', display: 'flex', marginBottom: '10px' }}>
          <div
            style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>Regio:</span>{' '}
              {realEstate.property?.location?.district || '-'} (
              {realEstate.property?.location?.postalCode || '-'})
            </div>
            <span>
              <span style={{ fontWeight: 'bold' }}>Straat:</span>{' '}
              {realEstate.property?.location?.street || '-'}{' '}
              {realEstate.property?.location?.number}
            </span>
            <div>
              <span style={{ fontWeight: 'bold' }}>Prijs:</span>{' '}
              {(realEstate.price?.mainValue &&
                `€${realEstate.price?.mainValue}`) ||
                '-'}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Bouwjaar:</span>{' '}
              {(realEstate.property?.constructionYear &&
                `${realEstate.property?.constructionYear}`) ||
                '-'}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>K.I.:</span>{' '}
              {(realEstate.transaction?.sale?.cadastralIncome &&
                `€${realEstate.transaction?.sale?.cadastralIncome}`) ||
                '-'}
            </div>
          </div>
          <div
            style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>
                Bewoonbare oppervlakte:
              </span>{' '}
              {(realEstate.property?.netHabitableSurface &&
                `${realEstate.property?.netHabitableSurface}m²`) ||
                '-'}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>Totale oppervlakte:</span>{' '}
              {(realEstate.property?.totalSurface &&
                `${realEstate.property?.totalSurface}m²`) ||
                '-'}
            </div>{' '}
            <div>
              <span style={{ fontWeight: 'bold' }}>Type woning:</span>{' '}
              {(realEstate.property?.type &&
                `${realEstateTypeMap[realEstate.property?.type || ''] ||
                realEstate.property?.type
                }`) ||
                '-'}
            </div>
            <div>
              <span style={{ fontWeight: 'bold' }}>EPC:</span>{' '}
              {(realEstate.property?.constructionYear &&
                `${realEstate.transaction?.certificates?.primaryEnergyConsumptionPerSqm}`) ||
                '-' ||
                '-'}
            </div>
          </div>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>
            {realEstate.property?.title}
          </span>
        </div>
        <div>{realEstate.property?.description}</div>
      </div>
    </div>
  );
};

export const getFormData = (elements: any) => {
  return Object.keys(elements).reduce((prev, next: any) => {
    if (elements[next].value && isNaN(parseInt(next))) {
      prev[next] = elements[next].value;
    }
    return prev;
  }, {} as { [key: string]: any });
};

interface FormData {
  postalCode: number | null;
  street: string | null;
  realEstateType: string | null;
  realEstateSubType: string | null;
  netHabitableSurface: number | null;
  totalSurface: number | null;
  constructionYear: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  cadastralIncome: number | null;
}
const RealEstates = () => {
  const [form, setForm] = useState<FormData>({
    postalCode: null,
    street: null,
    realEstateType: null,
    realEstateSubType: null,
    netHabitableSurface: null,
    totalSurface: null,
    constructionYear: null,
    fromDate: null,
    toDate: null,
    cadastralIncome: null,
  });
  const { loading, error, data, fetchMore, refetch } = useQuery<
    realEstates,
    realEstatesVariables
  >(REAL_ESTATES, {
    variables: { pagination: { page: 1 } },
  });
  const [isFullScreen, setIsFullScreen] =
    useState<realEstates_realEstates_data | null>(null);
  const [page, setPage] = useState(1);

  const getNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    console.log(nextPage);
    fetchMore({
      variables: { pagination: { page: nextPage, search: form } },
      updateQuery: ({ realEstates }: any, { fetchMoreResult }: any) => {
        return {
          realEstates: {
            ...realEstates,
            data: realEstates.data.concat(
              ...(fetchMoreResult?.realEstates.data || [])
            ),
            page: fetchMoreResult?.realEstates.page || 1,
            total: fetchMoreResult?.realEstates.total || 1,
          },
        };
      },
    });
  };

  if (error)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        Er ging iets fout. Gelieve je pagina te refreshen.
      </div>
    );
  const realEstates = data?.realEstates.data;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1400px',
        height: '100%',
        width: '100%',
        padding: '12px',
      }}>
      <Form
        style={{ marginBottom: '20px', width: '100%' }}
        onSubmit={(e: any) => {
          e.preventDefault();
          const formDataObj = { ...form };
          for (const key of Object.keys(formDataObj)) {
            if (!formDataObj[key as 'netHabitableSurface'])
              formDataObj[key as 'netHabitableSurface'] = null;
          }
          setPage(1);
          refetch({
            pagination: {
              page: 1,
              search: {
                ...formDataObj,
              },
            },
          });
        }}>
        <Form.Row>
          <div
            style={{
              width: '260px',
              display: 'flex',
              alignItems: 'center',
              height: '60px',
            }}>
            <img src={Logo} style={{ width: '60px', height: '60px' }} />
            <div
              style={{ marginLeft: '20px', fontSize: '30px', fontWeight: 100 }}>
              Immo History
            </div>
          </div>
          <Form.Group as={Col}>
            <Form.Row style={{ flexWrap: 'wrap' }}>
              <Form.Group as={Col}>
                <Select
                  escapeClearsValue
                  isClearable
                  placeholder="Gemeente - postcode"
                  options={[
                    { value: 9290, label: 'Berlare - 9290' },
                    { value: 9200, label: 'Dendermonde - 9200' },
                    { value: 9070, label: 'Destelbergen - 9070' },
                    { value: 9270, label: 'Laarne - 9270' },
                    { value: 9080, label: 'Lochristi - 9080' },
                    { value: 9160, label: 'Lokeren - 9160' },
                    { value: 9240, label: 'Zele - 9240' },
                    { value: 9100, label: 'Sint-Niklaas - 9100' },
                    { value: 9230, label: 'Wetteren - 9230' },
                    { value: 9260, label: 'Wichelen - 9260' },
                    { value: 9340, label: 'Lede - 9340' },
                    { value: 9300, label: 'Aalst - 9300' },
                    { value: 9220, label: 'Hamme - 9220' },
                    { value: 9420, label: 'Erpe-Mere - 9420' },
                    { value: 9450, label: 'Haaltert - 9450' },
                    { value: 9180, label: 'Moerbeke - 9180' },
                    { value: 9185, label: 'Wachtebeke - 9185' },
                    { value: 9250, label: 'Waasmunster - 9250' },
                    { value: 9280, label: 'Lebbeke - 9280' },
                  ]}
                  onChange={(value) => {
                    setForm({ ...form, postalCode: value?.value || null });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Select
                  escapeClearsValue
                  isClearable
                  placeholder="Type woning"
                  options={[
                    { value: 'APARTMENT', label: 'Appartement' },
                    { value: 'HOUSE', label: 'Huis' },
                    { value: 'LAND', label: 'Grond' },
                    { value: 'INDUSTRY', label: 'Industrie' },
                    { value: 'COMMERCIAL', label: 'Zaken' },
                    { value: 'APARTMENT_GROUP', label: 'Groep appartementen' },
                    { value: 'HOUSE_GROUP', label: 'Groep huizen' },
                  ]}
                  onChange={(value) => {
                    setForm({ ...form, realEstateType: value?.value || null });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Select
                  escapeClearsValue
                  isClearable
                  placeholder="Subtype"
                  options={[
                    {
                      label: 'Appartementen',
                      options: [
                        { label: 'Appartement', value: 'APARTMENT' },
                        { label: 'Penthouse', value: 'PENTHOUSE' },
                        { label: 'Service flat', value: 'SERVICE_FLAT' },
                        { label: 'Loft', value: 'LOFT' },
                        { label: 'Duplex', value: 'DUPLEX' },
                        { label: 'Triplex', value: 'TRIPLEX' },
                        { label: 'Studio', value: 'FLAT_STUDIO' },
                        { label: 'Appartementsblok', value: 'APARTMENT_BLOCK' },
                        { label: 'Gelijkvloers', value: 'GROUND_FLOOR' },
                      ],
                    },
                    {
                      label: 'Huizen',
                      options: [
                        { label: 'Huis', value: 'HOUSE' },
                        { label: 'Chalet', value: 'CHALET' },
                        { label: 'Villa', value: 'VILLA' },
                        { label: 'Mansion', value: 'MANSION' },
                        {
                          label: 'Gemengd gebruik',
                          value: 'MIXED_USE_BUILDING',
                        },
                        { label: 'Kasteel', value: 'CASTLE' },
                        { label: 'Bungalow', value: 'BUNGALOW' },
                        { label: 'Landelijk', value: 'COUNTRY_COTTAGE' },
                        {
                          label: 'Uitzonderlijke eigendom',
                          value: 'EXCEPTIONAL_PROPERTY',
                        },
                        { label: 'Stadswoning', value: 'TOWN_HOUSE' },
                        { label: 'Andere eigendom', value: 'OTHER_PROPERTY' },
                      ],
                    },
                    {
                      label: 'Commercieël',
                      options: [
                        { label: 'Zakelijk', value: 'BUSINESS' },
                        {
                          label: 'Gemengd gebruik',
                          value: 'MIXED_USE_BUILDING_COMMERCIAL',
                        },
                        {
                          label: 'Hotel - Restaurant - Cafe',
                          value: 'HOTEL_RESTAURANT_CAFE',
                        },
                        {
                          label: 'Commercieël vastgoed',
                          value: 'COMMERCIAL_PREMISES',
                        },
                      ],
                    },
                    {
                      label: 'Gronden',
                      options: [
                        { label: 'Grond', value: 'LAND' },
                        { label: 'Brouwgrond', value: 'BUILDING_LAND' },
                        { label: 'Bos', value: 'FOREST' },
                        { label: 'Landbouwgrond', value: 'AGRICULTURAL_AREA' },
                        { label: 'Geen brouwgrond', value: 'UNBUILDABLE_LAND' },
                      ],
                    },
                    {
                      label: 'Industrie',
                      options: [
                        {
                          label: 'Industrieël vastgoed',
                          value: 'INDUSTRIAL_PREMISES',
                        },
                        { label: 'Warenhuis', value: 'WAREHOUSE' },
                        {
                          label: 'Gemengd gebruik',
                          value: 'MIXED_USE_BUILDING_INDUSTRIES',
                        },
                      ],
                    },
                  ]}
                  onChange={(value) => {
                    const castedValue = value as unknown as {
                      label: string;
                      value: string;
                    };
                    setForm({
                      ...form,
                      realEstateSubType: castedValue?.value || null,
                    });
                  }}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Control
                  type="straat"
                  placeholder="Straat"
                  name="street"
                  onChange={(e) =>
                    setForm({ ...form, street: e.target.value || null })
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="number"
                  placeholder="Bouwjaar"
                  name="constructionYear"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      constructionYear: parseInt(e.target.value, 10) || null,
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="number"
                  placeholder="Min. bewoonbare opp."
                  name="netHabitableSurface"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      netHabitableSurface: parseInt(e.target.value, 10) || null,
                    })
                  }
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="number"
                  placeholder="Min. totale opp."
                  name="totalSurface"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      totalSurface: parseInt(e.target.value, 10) || null,
                    })
                  }
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <DatePicker
                  placeholderText="Begin datum"
                  selected={form.fromDate}
                  isClearable
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: Date) => {
                    setForm({
                      ...form,
                      fromDate:
                        (date && moment(date).startOf('day').utc().toDate()) ||
                        null,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <DatePicker
                  placeholderText="Eind datum"
                  selected={form.toDate}
                  isClearable
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date: Date) => {
                    console.log({ date });
                    setForm({
                      ...form,
                      toDate:
                        (date && moment(date).endOf('day').utc().toDate()) ||
                        null,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Control
                  type="number"
                  placeholder="Kadastraal inkomen"
                  name="cadastralIncome"
                  onChange={(e) =>
                    setForm({
                      ...form,
                      cadastralIncome: parseInt(e.target.value, 10) || null,
                    })
                  }
                />
              </Form.Group>
            </Form.Row>
            <Form.Row style={{ alignItems: 'center' }}>
              <Button variant="primary" type="submit">
                Zoeken
              </Button>
              <div style={{ marginLeft: '16px' }}>
                {data?.realEstates.total || 0} resultaten
              </div>
            </Form.Row>
          </Form.Group>
        </Form.Row>
      </Form>
      {isFullScreen && (
        <RealEstateCarousel
          realEstate={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          isFullScreen
        />
      )}
      {loading && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}>
          <Spinner animation="border" role="status" />
        </div>
      )}
      {!loading && data?.realEstates.data?.length === 0 && (
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          Geen resultaten gevonden
        </div>
      )}
      {!loading &&
        realEstates &&
        data?.realEstates?.data &&
        (data?.realEstates?.data?.length || 0 > 0) && (
          <div
            style={{ width: '100%', height: '100%', overflow: 'auto' }}
            id="scrollableDiv">
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={data.realEstates.data.length}
              next={() => {
                getNextPage();
              }}
              hasMore={data.realEstates.data.length < data.realEstates.total}
              loader={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50px',
                    width: '100%',
                  }}>
                  <Spinner animation="border" role="status" />
                </div>
              }>
              {realEstates.map((realEstate, index) => (
                <RealEstate
                  key={`RealEstate${index}`}
                  realEstate={realEstate}
                  setIsFullScreen={setIsFullScreen}
                />
              ))}
            </InfiniteScroll>
          </div>
        )}
    </div>
  );
};

export default RealEstates;
