import { gql } from '@apollo/client';

export const REAL_ESTATES = gql`
  query realEstates($pagination: PaginationInput!) {
    realEstates(pagination: $pagination) {
      data {
        id
        media {
          pictures {
            largeUrl
          }
        }
        property {
          constructionYear
          description
          name
          title
          totalSurface
          netHabitableSurface
          type
          location {
            street
            number
            district
            postalCode
          }
        }
        publication {
          creationDate
        }
        price {
          mainValue
        }
        transaction {
          certificates {
            primaryEnergyConsumptionPerSqm
          }
          sale {
            cadastralIncome
          }
        }
      }
      total
      page
    }
  }
`;
