import './App.css';
import RealEstates from './modules/RealEstates';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { onError } from 'apollo-link-error';
import Login from './modules/Login';
import { setContext } from '@apollo/client/link/context';

const history = createBrowserHistory();

const PrivateRoute = ({ permissions = [], ...props }) => {
  if (!localStorage.getItem('loginToken')) {
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  }
  return <Route {...props} />;
};

const httpLink = createHttpLink({
  uri: process.env.NODE_ENV === 'production' ? '/graphql' : 'http://localhost:4000/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('loginToken');
  if (!token) return { headers };
  return { headers: { ...headers, authorization: `Bearer ${token}` } };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError((apolloError) => {
      const { graphQLErrors } = apolloError;
      if (graphQLErrors) {
        const isAuthorizationError = graphQLErrors.find(
          ({ extensions, message }) =>
            (extensions && extensions.code === 'UNAUTHENTICATED') || message.includes('Context creation failed:')
        );
        if (isAuthorizationError) {
          localStorage.removeItem('loginToken');
          history.push('/login');
        }
      }
    }) as unknown as any,
    authLink,
    httpLink,
  ]),
});

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/vastgoed" component={RealEstates} />
            <Route render={() => <Redirect to={{ pathname: '/vastgoed' }} />} />
          </Switch>
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
